import React from 'react';
import { useTranslation } from 'react-i18next';
import Head from '../Head';
import Footer from '../Footer';

function Location() {
	const { t } = useTranslation('website');

	return (
		<main className='main location'>
			<Head title={t('head.04.t')} description={t('head.04.d')} keywords={t('head.04.k')} />

			<h1>{t('location.01')}</h1>
			<h2>{t('location.02')}</h2>

			<section className='content'>
				<img src='/img/abrangency-map.svg' alt={t('location.01')} />
				<div className='text'>
					<p>{t('location.03.a')}</p>
					<p>
						{t('location.03.b')}
						<span className='green'> {t('location.03.c')} </span>
						{t('location.03.d')}
					</p>
				</div>
			</section>

			<Footer />
		</main>
	);
}

export default Location;
