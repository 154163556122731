import React from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
// --------------------------------------------------
import Header from "./Header";
// import Footer from "./Footer";
// --------------------------------------------------
import Home from "./home/Home";
import About from "./about/About";
import Solutions from "./solutions/Solutions";
import Location from "./location/Location";
import Contact from "./contact/Contact";
import NotFound from "./NotFound";

function App() {
	return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about/*" element={<About />} />
                <Route path="/solutions" element={<Solutions />} />
                <Route path="/location" element={<Location />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            {/* <Footer /> */}
        </BrowserRouter>
	);
}

export default App;
