import React from "react";
import Head from '../Head';
import { Slider } from "./TextContext";
import TextContent from "./TextContent";
import { useTranslation } from "react-i18next";

function Home() {
	const { t } = useTranslation('website');

	return (
		<main className="main home">
			<Head title={t('head.01.t')} description={t('head.01.d')} keywords={t('head.01.k')} />

			<Slider>
				<TextContent />
			</Slider>
		</main>
	);
}

export default Home;