import React from "react";
import { useTranslation } from "react-i18next";
import { TextContext } from "./TextContext";
import TypingEffect from "./TypingEffect";

function TextContent() {

	const txtContext = React.useContext(TextContext);

    const { t } = useTranslation('website');

    return (
        <div className="text-c">
            <TypingEffect className="tx tx-1" text={t('home.sl' + txtContext.slide + '.01')} current="text1" next="setText2" />
            <TypingEffect className="tx tx-2" text={t('home.sl' + txtContext.slide + '.02')} current="text2" next="setText3" />
            <TypingEffect className="tx tx-3" text={t('home.sl' + txtContext.slide + '.03')} current="text3" next="setText4" />
            <TypingEffect className="tx tx-4" text={t('home.sl' + txtContext.slide + '.04')} current="text4" finish={true} />
        </div>
    );
}

export default TextContent;