import React from "react";
import { useTranslation } from "react-i18next";
import Head from '../Head';
import Footer from "../Footer";

function About() {
	const { t } = useTranslation('website');

	return (
		<main className="main about">
			<Head title={t('head.02.t')} description={t('head.02.d')} keywords={t('head.02.k')} />

			<h1>
				<span className="ht t1">Code</span>
				<span className="ht t2">2</span>
				<span className="ht t3">Business</span>
			</h1>
			<h2>{t('about.01')}</h2>

			<section className="content">
				<p>{t('about.02')}</p>
				<p>
					{t('about.03')}<br />
					{t('about.04.a')} <i>{t('about.04.b')}</i> {t('about.04.c')}
				</p>
				<h3>{t('about.05')}</h3>
				<p>{t('about.06')}</p>
				<h3>{t('about.07')}</h3>
				<p>{t('about.08')}</p>
				<h3>{t('about.09')}</h3>
				<p>{t('about.10')}</p>
			</section>
			<Footer />
		</main>
	);
}

export default About;