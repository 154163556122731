import React from "react";

const Footer = () => {

	let date = new Date();

	return (
		<footer>
			<span>Code2Business &copy; 2018-{date.getFullYear()} - All Rights Reserved</span>
		</footer>
	);
};

export default Footer;