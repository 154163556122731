import React from "react";
import { useTranslation } from "react-i18next";
import Head from './Head';

function NotFound() {
	const { t } = useTranslation('website');

	return (
		<main className="main notfound">
			<Head title={t('notfound.01')} description={t('notfound.01')} keywords={t('notfound.01')} />

			<section className="content">
				<div className="tx tx-1">404</div>
				<div className="tx tx-2">{t('notfound.02')}</div>
				<div className="tx tx-3">{t('notfound.03')}</div>
			</section>
		</main>
	);
}

export default NotFound;