import React from "react";
import { useTranslation } from "react-i18next";
import Head from '../Head';
import Footer from "../Footer";

function Contact() {
	const { t } = useTranslation('website');

	return (
		<main className="main contact">
			<Head title={t('head.05.t')} description={t('head.05.d')} keywords={t('head.05.k')} />

			<h1>{t('contact.01')}</h1>
			<h2>{t('contact.02')}</h2>

			<section className="content">
				<div className="col col-1">
					<div className="item">
						<a href="mailto:contato&#64;code2business&#46;com">
							<img src="/img/contact-mail.svg" alt="email"/>
						</a>
						<a href="mailto:contato&#64;code2business&#46;com">contato&#64;code2business&#46;com</a>
					</div>
					<div className="item">
						<a href="https://api.whatsapp.com/send?phone=5519983581857&text=Olá,%20peguei%20o%20contato%20no%20seu%20site" target="_blank" rel="noreferrer">
							<img src="/img/contact-whatsapp.svg" alt="email"/>
						</a>
						<a href="https://api.whatsapp.com/send?phone=5519983581857&text=Olá,%20peguei%20o%20contato%20no%20seu%20site" target="_blank" rel="noreferrer">+55 19 98358-1857</a>
					</div>
				</div>
				<div className="col col-2">
					<div className="img-c">
						<img src="/img/code2business-vcard.png" alt="email"/>
					</div>
					<a href="/code2business.vcf" target="_blank" className="button">{t('contact.03')}</a>
				</div>
			</section>

			<Footer />
		</main>
	);
}

export default Contact;