import React from "react";
import { useTranslation } from "react-i18next";
import Head from '../Head';
import Footer from "../Footer";

function Solutions() {
	const { t } = useTranslation('website');

	return (
		<main className="main solutions">
			<Head title={t('head.03.t')} description={t('head.03.d')} keywords={t('head.03.k')} />

			<h1>{t('solutions.01')}</h1>
			<h2>{t('solutions.02')}</h2>

			<section className="content">
				<div className="col">
					<img src="/img/solution-1.png" alt={t('solutions.03')}/>
					<h3>{t('solutions.03')}</h3>
					<p>{t('solutions.04')}</p>
					<p className="bold">
						{t('solutions.05.a')}<br />
						{t('solutions.05.b')}
					</p>
				</div>
				<div className="col">
					<img src="/img/solution-2.png" alt={t('solutions.06')}/>
					<h3>{t('solutions.06')}</h3>
					<p>{t('solutions.07')}</p>
					<p className="bold">{t('solutions.08')}</p>
				</div>
				<div className="col">
					<img src="/img/solution-3.png" alt={t('solutions.09')}/>
					<h3>{t('solutions.09')}</h3>
					<p>{t('solutions.10')}</p>
					<p className="bold">{t('solutions.11')}</p>
				</div>
			</section>

			<Footer />
		</main>
	);
}

export default Solutions;