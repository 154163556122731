import React from "react";

export const TextContext = React.createContext();

export const Slider = ({children}) => {
    const [slide, setSlide] = React.useState(1);
    const [text1, setText1] = React.useState(true);
    const [text2, setText2] = React.useState(false);
    const [text3, setText3] = React.useState(false);
    const [text4, setText4] = React.useState(false);

	function nextSlide(){
		setTimeout(() => {
			setSlide((slide >= 4) ? 1 : slide + 1);

			setText4(false);
			setText3(false);
			setText2(false);
			setText1(true);
		}, 5000);
	};

	return (
		<TextContext.Provider value={{ slide, nextSlide, text1, text2, text3, text4, setText1, setText2, setText3, setText4 }}>
			{children}
		</TextContext.Provider>
	);
};
