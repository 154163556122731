import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
	const [t, i18n] = useTranslation('website');

	let lsLang = localStorage.getItem('lang');

	const [lang, setLang] = React.useState(lsLang || 'pt');

	React.useEffect(() => {
		let lsLang = localStorage.getItem('lang');

		if (lsLang == lang) {
			return;
		}

		i18n.changeLanguage(lang);

		localStorage.setItem('lang', lang);
	}, [i18n, lang]);

	return (
		<React.Fragment>
			<header>
				<img src='/img/code2business-logo.svg' className='logo' alt='Code2Business Logo' />
			</header>
			<div className='lang'>
				<img src='/img/flag_pt.jpg' className='flag' onClick={() => setLang('pt')} alt='bandeira do brasil' />
				<img src='/img/flag_en.jpg' className='flag' onClick={() => setLang('en')} alt='american flag' />
				{/* <img src="/img/flag_pt.jpg" className="flag" onClick={() => i18n.changeLanguage('pt')} alt="bandeira do brasil" />
				<img src="/img/flag_en.jpg" className="flag" onClick={() => i18n.changeLanguage('en')} alt="american flag"/> */}
			</div>
			<nav>
				<NavLink className='menu-item' to='/' end>
					<div className='icon-c'>
						<img src='/img/menu-1-home.svg' className='icon' alt='menu icon' />
					</div>
					<div className='text'>{t('header.01')}</div>
				</NavLink>
				<NavLink className='menu-item' to='/about' end>
					<div className='icon-c'>
						<img src='/img/menu-2-about.svg' className='icon' alt='menu icon' />
					</div>
					<div className='text'>{t('header.02')}</div>
				</NavLink>
				<NavLink className='menu-item' to='/solutions' end>
					<div className='icon-c'>
						<img src='/img/menu-3-solutions.svg' className='icon' alt='menu icon' />
					</div>
					<div className='text'>{t('header.03')}</div>
				</NavLink>
				<NavLink className='menu-item' to='/location' end>
					<div className='icon-c'>
						<img src='/img/menu-4-location.svg' className='icon' alt='menu icon' />
					</div>
					<div className='text'>{t('header.04')}</div>
				</NavLink>
				<NavLink className='menu-item' to='/contact' end>
					<div className='icon-c'>
						<img src='/img/menu-5-contact.svg' className='icon' alt='menu icon' />
					</div>
					<div className='text'>{t('header.05')}</div>
				</NavLink>
			</nav>
		</React.Fragment>
	);
};

export default Header;
