import React from "react";

const Head = (props) => {

	React.useEffect(() => {
		let metaDescription = document.querySelector('meta[name="description"]');
		let metaKeywords = document.querySelector('meta[name="keywords"]');
		let metaOgDesc = document.querySelector('meta[property="og:description"]');
		let metaOgTittle = document.querySelector('meta[property="og:title"]');

		metaDescription.content = props.description || '';
		metaKeywords.content = props.keywords || '';
		metaOgDesc.content = props.description || '';

		document.title = props.title;
		metaOgTittle.content = props.title || '';

	}, [props]);

	return null;
};

export default Head;