import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// --------------------------------------------------
import './css/reset.css';
import './css/website.css';
// --------------------------------------------------
import LangPT from './langs/pt.json';
import LangEN from './langs/en.json';
// --------------------------------------------------
import App from './App';

let dfLang = localStorage.getItem('lang');

if (!dfLang) {
	dfLang = navigator.language.match(/^en/i) ? 'en' : 'pt';
}
localStorage.setItem('lang', dfLang);

i18next.init({
	interpolation: { escapeValue: false }, // React already does escaping
	lng: dfLang || 'pt', // language to use
	resources: {
		pt: {
			website: LangPT,
		},
		en: {
			website: LangEN,
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18next}>
			<App />
		</I18nextProvider>
	</React.StrictMode>
);
