import React from "react";
import { TextContext } from "./TextContext";

const TypingEffect = ({className, text, current, next = null, finish = false}) => {

	const txtContext = React.useContext(TextContext);

	const [paramText, setParamText] = React.useState('');
	const [renderText, setRenderText] = React.useState('');
	const [renderCount, setRenderCount] = React.useState(0);

	if (paramText !== text) {
		setParamText(text.replace(/\s+/g, ' '));
		setRenderText('');
		setRenderCount(0);
	}

	if (!txtContext[current]){
		return (
			<div className={className}>{String.fromCharCode(160)}</div>
		);
	}

	if (renderCount <= text.length) {
		setTimeout(() => {
            let char = paramText[renderCount] ?? '';
            let inc = 1;

            if (char.match(/\s/)) {
                setRenderText(renderText +char+ (paramText[(renderCount + 1)] ?? ''));
                inc = 2;
            }
            else {
                setRenderText(renderText + char);
            }

			setRenderCount(renderCount + inc);
		}, 50);
	}
	else
	{
		if (txtContext[next]) {
			txtContext[next](true);
		}

        if (finish){
            txtContext.nextSlide();
        }
	}

	return (
		<div className={className}>{renderText ? renderText : String.fromCharCode(160)}</div>
	);
};

export default TypingEffect;